import React, { useContext } from "react"
import LineIcon from "react-lineicons"
import {
  Card,
  Accordion,
  useAccordionToggle,
  AccordionContext,
} from "react-bootstrap"
import { Link } from "gatsby"

export const Toggle = props => {
  const isBrowser = typeof window !== "undefined"
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(props.eventKey, e => {
    e.preventDefault()
  })
  const isCurrentEventKey = currentEventKey === props.eventKey

  return (
    <Link
      to="#"
      onClick={decoratedOnClick}
      className="d-flex justify-content-between"
      role="button"
    >
      <span>{props.children}</span>
      <span>
        {isBrowser && <LineIcon name={isCurrentEventKey ? "minus" : "plus"} />}
      </span>
    </Link>
  )
}

export const Faq = props => {
  return (
    <Accordion defaultActiveKey="0">
      {props.data.map(data => (
        <Card className="mb-10" key={data.id}>
          <Card.Header>
            <Toggle eventKey={data.id}>{data.question}</Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey={data.id}>
            <Card.Body className="lead">{data.answer}</Card.Body>
          </Accordion.Collapse>
        </Card>
      ))}
    </Accordion>
  )
}
