import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../components/faq.css"
import { Faq } from "../components/faq"
import Banner from "../components/banner"

const data = [
  {
    id: "0",
    question: "Why do I choose Lappy Nation?",
    answer:
      "Lappy nation is reliable, cost effective, one day delivery, easy renewals, flexible rental periods, wide range of products, transparent",
  },
  {
    id: "1",
    question: "What are the products do you rent?",
    answer:
      "We offer rental services for Laptops, Desktops, Printers, and CCTVs.",
  },
  {
    id: "2",
    question: "What is the procedure to rent a laptop?",
    answer:
      "You may connect with us through phone/ email/ or in person- Share your need of the product- Check the product-Select your subscription period- Sign the agreement- Make payment and its done.",
  },
  {
    id: "3",
    question: "What are the brands available?",
    answer:
      "We are dealing into various brands of Computers/ Laptops/ Printers/ Security systems such as DELL/ HP/ LENOVO/ APPLE/SAMSUNG/CP PLUS.",
  },
  {
    id: "4",
    question: "Is any apple range is available on rent?",
    answer:
      "Yes, we have IMac and MacBook Pro. IMac: i5, 4GB, 8GB , MacBook Pro: i5 4GB , i5 8GB",
  },
  {
    id: "5",
    question: "What is the mode of payment?",
    answer:
      "Payment can be done through Cheque/ NEFT/ RTGS/ IMPS/ UPI/ Phonepe/ Paytm",
  },
  {
    id: "6",
    question: "What is the rental period?",
    answer:
      "One rent for different time period such as 1Month/ 3Months/ 6 months/ 9 months/ a Year and more",
  },
  {
    id: "7",
    question: "How can one renew the rental period?",
    answer:
      "Its really easy to renew your renew the rental after the completion of the period",
  },
  {
    id: "8",
    question: "Can we make payment after the Rental period is over?",
    answer: "NO, complete payment needs to be done in ADVANCE.",
  },
  {
    id: "9",
    question: "Is it compulsory to give the security of the product?",
    answer:
      "Yes, it is mandatory to deposit the one month security amount and it will be refundable at the time of call off.",
  },
  {
    id: "10",
    question: "Is there any minimal rental period?",
    answer: "Yes, it is of 1 month.",
  },
  {
    id: "11",
    question: "What if we have any technical problem in the system?",
    answer: "Do connect with us via phone: +918376007110.",
  },
]

const FaqPage = () => (
  <Layout>
    <Seo title="FAQ" />
    <Banner title="Frequently Asked Questions" />
    <section id="faq" className="faq-area single-features pt-5 pb-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="faq-content mt-45">
              <div className="about-accordion">
                <Faq data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default FaqPage
